<template>
  <div class="gateway__toolbar d-flex mb-4 align-center">
    <v-select
        v-model="userSelectedClient"
        label="Client"
        item-text="title"
        :items="clients"
        hint="Select client"
        persistent-hint
        return-object
        class="mr-4"
        @change="resetSelectedValues(); fetchSites()"/>
    <v-select
        v-model="userSelectedSite"
        item-text="title"
        :disabled="!selectedClient"
        label="Site"
        :items="sites"
        hint="Select site"
        persistent-hint
        return-object
        class="mr-4"
        @change="resetSelectedGateway(); fetchGateways()"/>
    <v-select
        v-model="userSelectedGateway"
        :disabled="!selectedSite"
        label="Gateway"
        :items="gatewaysInSelectedSite"
        hint="Select gateway to edit"
        persistent-hint
        return-object
        class="mr-4"/>
    <slot name="append"/>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';

export default {
  name: 'GatewaySelector',
  computed: {
    ...mapState('dashboard/gateway', [
      'selectedClient',
      'clients',
      'sites',
      'selectedSite',
      'selectedGateway'
    ]),
    ...mapGetters('dashboard/gateway', [
      'gatewaysInSelectedSite'
    ]),

    userSelectedClient: {
      get() {
        return this.selectedClient;
      },
      set(value) {
        this.setSelectedClient(value);
      }
    },
    userSelectedSite: {
      get() {
        return this.selectedSite;
      },
      set(value) {
        this.setSelectedSite(value);
      }
    },
    userSelectedGateway: {
      get() {
        return this.selectedGateway;
      },
      set(value) {
        this.setSelectedGateway(value);
      }
    }
  },
  methods: {
    ...mapMutations('dashboard/gateway', [
      'setSelectedClient',
      'resetSelectedValues',
      'setSelectedSite',
      'resetSelectedGateway',
      'setSelectedGateway'
    ]),
    ...mapActions('dashboard/gateway', [
      'fetchSites',
      'fetchGateways'
    ])
  }
};
</script>

<style lang="scss" scoped>
.gateway {
  &__toolbar {
    max-width: 960px;
    margin: 0 auto;
  }
}
</style>
