<template>
  <v-container class="pa-0" fluid>
    <v-toolbar>
      <v-toolbar-title>Stats</v-toolbar-title>
      <v-spacer/>
      <v-btn
          outlined
          color="accent darken-1"
          :href="downloadUrl"
          :download="downloadFileName">
        <v-icon left>mdi-download</v-icon>
        Download CSV
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-data-table
          :headers="dataHeaders"
          :items="nsTotals"
          item-key="id"
          show-expand
          disable-pagination
          hide-default-footer
          class="elevation-1 ma-4"
          sort-by="title">
        <template #item="{item, expand, isExpanded}">
          <tr>
            <td>
              <v-icon @click="expand(false)" v-if="isExpanded">mdi-chevron-up</v-icon>
              <v-icon @click="expand(true)" v-else>mdi-chevron-down</v-icon>
            </td>
            <td>{{ item.title }}</td>
            <td align="center"><span class="numeric">{{ item.deskCount }}</span></td>
            <td align="center"><span class="numeric">{{ item.roomCount }}</span></td>
            <td align="center"><span class="numeric">{{ item.kioskCount }}</span></td>
          </tr>
          <!--
          The following renders additional rows for the expanded content - this is the easiest way to keep the columns
          aligned!
          -->
          <template v-if="isExpanded">
            <tr
                class="nested-table"
                v-for="site in Object.values(item.sites).sort((s1, s2) => s1.title.localeCompare(s2.title))"
                :key="item.title+site.title">
              <td/>
              <td>{{ site.title }}</td>
              <td align="center"><span class="numeric">{{ site.deskCount || '-' }}</span></td>
              <td align="center"><span class="numeric">{{ site.roomCount || '-' }}</span></td>
              <td align="center"><span class="numeric">{{ site.kioskCount || '-' }}</span></td>
            </tr>
          </template>
        </template>
        <!-- don't show any content in the default expanded item slot -->
        <template #expanded-item/>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
// @ is an alias to /src

import {mapGetters} from 'vuex';
import {Downloader} from '@/util/files';

export default {
  name: 'StatsView',
  data() {
    return {
      dataHeaders: [
        {text: 'Client', value: 'title'},
        {text: 'Desks', value: 'deskCount', width: '10%', align: 'right'},
        {text: 'Rooms', value: 'roomCount', width: '10%', align: 'right'},
        {text: 'Kiosks', value: 'kioskCount', width: '10%', align: 'right'}
      ],
      downloader: new Downloader({type: 'data:text/csv'})
    };
  },
  computed: {
    ...mapGetters('dashboard', ['stats']),
    nsTotals() {
      const data = Object.keys(this.stats);
      return data.map((key) => {
        const ns = this.stats[key];
        let desks = 0;
        let rooms = 0;
        let kiosks = 0;
        for (const site of Object.values(ns.sites)) {
          if (site.hasOwnProperty('deskCount')) {
            desks += site.deskCount;
          }
          if (site.hasOwnProperty('roomCount')) {
            rooms += site.roomCount;
          }
          if (site.hasOwnProperty('kioskCount')) {
            kiosks += site.kioskCount;
          }
        }
        return {
          id: key,
          title: ns.title,
          deskCount: desks,
          roomCount: rooms,
          kioskCount: kiosks,
          sites: Object.values(ns.sites)
        };
      });
    },
    downloadFileName() {
      return 'kahu-stats.csv';
    },
    downloadUrl() {
      const content = this.fileContent;
      if (!content) {
        return null;
      }
      return this.downloader.createDataUrl(content);
    },
    fileContent() {
      const header = ['Client', 'Site Name', 'Desk Count', 'Room Count', 'Kiosk Count'].join(',');
      const toLine = (r, title = r.title, site = '') => [
        this.encodeCsvField(title),
        this.encodeCsvField(site),
        r.deskCount || 0,
        r.roomCount || 0,
        r.kioskCount || 0
      ].join(',');
      const lines = this.nsTotals
          .map(ns => [
            toLine(ns),
            ...ns.sites.map(s => toLine(s, ns.title, s.title))
          ])
          .flat()
          .join('\r\n');
      return `${header}\r\n${lines}`;
    }
  },
  destroyed() {
    this.downloader.dispose();
  },
  methods: {
    encodeCsvField(field) {
      const hasSpecialChar = /["\r\n,]/.test(field);
      if (!hasSpecialChar) return field;
      return `"${field.replace(/["]/g, '""')}"`;
    }
  }
};
</script>

<style>
header.v-toolbar {
  z-index: 10;
}
.nested-table td {
  background: #f5f5f5;
}

.numeric {
  text-align: right;
  font-variant-numeric: tabular-nums;
  display: block;
}
</style>
