import stats from './stats';
import billing from './billing';
import gateway from './gateway';

export default {
  namespaced: true,
  state: {
  },
  modules: {
    billing,
    stats,
    gateway
  }
};
