<template>
  <v-container class="billing pa-4 mx-lg-auto">
    <div class="d-flex mb-4 align-center">
      <v-select
          v-model="reseller"
          label="Reseller"
          :items="resellerSelects"
          @change="setReseller"
          hide-details
          class="mr-4"/>
      <v-spacer/>
      <v-select
          v-model="statement"
          label="Statement Date"
          :items="statementSelects"
          hide-details
          class="mr-4"/>
      <v-btn
          outlined
          color="accent darken-1"
          :href="downloadUrl"
          :download="downloadFileName">
        <v-icon left>mdi-download</v-icon>
        Download CSV
      </v-btn>
    </div>
    <v-card v-for="ns in Object.keys(itemsByCustomer).sort()" :key="ns" class="mb-4">
      <v-card-title>{{ itemsByCustomer[ns].title }}</v-card-title>
      <v-data-table
          :headers="dataHeaders"
          class="elevation-0"
          :items="itemsByCustomer[ns].items"
          :sort-by.sync="sortByPerCustomer[ns]"
          :custom-sort="customSort"
          disable-pagination
          hide-default-footer>
        <template #item.title="{item}">{{ item.unit.title }} | {{ item.pricing.title }}</template>
        <template #item.quantity="{item}"><span class="numeric">{{ item.quantity }}</span></template>
      </v-data-table>
      <v-card-subtitle>Totals</v-card-subtitle>
      <v-data-table
          :headers="dataHeaders"
          class="elevation-0"
          :items="totalsByCustomer[ns]"
          disable-pagination
          hide-default-header
          hide-default-footer>
        <template #item="{item,headers}">
          <tr>
            <td>{{ item.title }}</td>
            <td :style="`width:${headers[1].width};`"><span class="numeric">{{ item.quantity }}</span></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

import {mapActions, mapGetters} from 'vuex';
import {Downloader} from '@/util/files';

export default {
  name: 'BillingView',
  data() {
    return {
      dataHeaders: [
        {text: 'Description', value: 'title'},
        {text: 'Qty', value: 'quantity', width: '100px', align: 'right'}
      ],
      statement: {
        items: []
      },
      reseller: '',
      downloader: new Downloader({type: 'data:text/csv'})
    };
  },
  computed: {
    ...mapGetters('dashboard', ['resellers', 'statements']),
    downloadFileName() {
      let statement = this.statement && this.statement.title || 'statement';
      let reseller = this.reseller && this.reseller.title || 'reseller';
      statement = statement.replace(/ /g, '-').toLowerCase();
      reseller = reseller.replace(/ /g, '-').toLowerCase();
      return reseller + '_' + statement + '.csv';
    },
    downloadUrl() {
      const content = this.fileContent;
      if (!content) {
        return null;
      }
      return this.downloader.createDataUrl(content);
    },
    fileContent() {
      const header = ['Client', 'Site Name', 'Component', 'Qty'].join(',');
      const toLine = (client, site, component, qty) => [
        this.encodeCsvField(client),
        this.encodeCsvField(site),
        this.encodeCsvField(component),
        this.encodeCsvField(qty)
      ].join(',');
      const clients = Object.keys(this.itemsByCustomer);
      const lines = [];
      for (const client of clients) {
        const {title, items} = this.itemsByCustomer[client];
        for (const item of items) {
          lines.push(toLine(title, item.unit.title, item.pricing.title, item.quantity));
        }
        const totals = this.totalsByCustomer[client] || [];
        for (const total of totals) {
          lines.push(toLine(title, '...Total', total.title, total.quantity));
        }
      }
      lines.sort((a, b) => a.localeCompare(b));
      return `${header}\r\n${lines.join('\r\n')}`;
    },
    sortByPerCustomer() {
      return this.statement.items.reduce((obj, item) => {
        obj[item.ns.ref.id] = 'title';
        return obj;
      }, {});
    },
    itemsByCustomer() {
      // create object with items array, keyed by ns
      return this.statement.items.reduce((obj, item) => {
        const ns = item.ns.ref.id;
        // add to customer
        if (!obj.hasOwnProperty(ns)) {
          obj[ns] = {title: item.ns.title, items: []};
        }
        obj[ns].items.push(item);
        return obj;
      }, {});
    },
    totalsByCustomer() {
      // create object with total quantities per item type, keyed by ns
      const totals = this.statement.items.reduce((obj, item) => {
        const ns = item.ns.ref.id;
        const type = item.pricing.ref.id;
        if (!obj.hasOwnProperty(ns)) {
          obj[ns] = {};
        }
        if (!obj[ns].hasOwnProperty(type)) {
          obj[ns][type] = {title: item.pricing.title, quantity: 0};
        }
        obj[ns][type].quantity += item.quantity;
        return obj;
      }, {});
      // convert each ns's keyed pricing types object into an array so we can show it in a table
      for (const ns of Object.keys(totals)) {
        totals[ns] = Object.values(totals[ns]);
      }
      return totals;
    },
    resellerSelects() {
      return this.resellers.map(r => {
        return {text: r.title, value: r};
      });
    },
    statementSelects() {
      return this.statements.map(s => {
        return {text: s.title, value: s};
      });
    }
  },
  watch: {
    resellers: {
      handler(r) {
        if (r.length > 0) {
          this.reseller = r[0];
          this.setReseller(r[0]);
        } else {
          this.reseller = '';
        }
      },
      immediate: true
    },
    statements(s) {
      if (s.length > 0) {
        this.statement = s[0];
      }
    }
  },
  methods: {
    ...mapActions('dashboard', ['setReseller']),
    // (items: any[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: Record<string, compareFn>)
    customSort(items, sortBy, sortDesc) {
      if (sortBy === null || !sortBy.length) return items;
      items.sort((a, b) => {
        if (sortBy[0] === 'title') {
          const aa = a.unit.title + a.pricing.title;
          const bb = b.unit.title + b.pricing.title;
          if (!sortDesc[0]) {
            return aa.localeCompare(bb);
          } else {
            return bb.localeCompare(aa);
          }
        } else if (sortBy[0] === 'quantity') {
          if (!sortDesc[0]) {
            return a.quantity - b.quantity;
          } else {
            return b.quantity - a.quantity;
          }
        }
      });
      return items;
    },
    encodeCsvField(field) {
      const hasSpecialChar = /["\r\n,]/.test(field);
      if (!hasSpecialChar) return field;
      return `"${field.replace(/["]/g, '""')}"`;
    }
  }
};
</script>

<style>
.billing {
  max-width: 960px;
}

.nested-table td {
  background: #f5f5f5;
}

.numeric {
  text-align: right;
  font-variant-numeric: tabular-nums;
  display: block;
}
</style>
