import versions from './versions';
import loading from './loading';

export default {
  namespaced: true,
  state: {},
  modules: {
    loading,
    versions
  }
};
