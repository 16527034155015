<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-progress-circular indeterminate size="48" color="accent"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Loading',
  computed: {
    ...mapGetters('app', ['loaded']),
    redirect() {
      return this.$route.params.redirect ? this.$route.params.redirect : {name: 'stats'};
    }
  },
  watch: {
    loaded: {
      handler(loaded) {
        if (loaded) {
          this.$logger.debug('app loaded, redirecting to: ', this.redirect);
          // make sure all the getters have updated before redirecting
          this.$nextTick(() => {
            this.$router.replace(this.redirect)
                .catch(() => {
                  // no error is every thrown just show the call has been aborted
                  this.$logger.debug('aborted');
                });
          });
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>

</style>
