<template>
  <div>
    <v-btn
        outlined
        color="accent darken-1"
        class="mr-4"
        :loading="isSelectingFile"
        :disabled="!selectedSite"
        @click="handleFileImport">
      <v-icon left>mdi-import</v-icon>
      Import
    </v-btn>
    <input
        ref="uploader"
        class="d-none"
        type="file"
        accept=".json"
        @change="onFileChanged">
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import {db} from '@/app/firebase';

export default {
  name: 'GatewayImport',
  data() {
    return {
      isSelectingFile: false
    };
  },
  computed: {
    ...mapState('dashboard/gateway', [
      'selectedSite',
      'loading'
    ])
  },
  methods: {
    ...mapActions('dashboard/gateway', [
      'ifDeskExistsOnSite'
    ]),
    ...mapMutations('dashboard/gateway', [
      'setLoading'
    ]),

    handleFileImport() {
      this.isSelectingFile = true;

      window.addEventListener('focus', () => {
        this.isSelectingFile = false;
      }, {once: true});

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      const file = e.target.files[0];
      try {
        this.setLoading(true);
        const fileContent = await this.readFile(file);
        const jsonData = JSON.parse(fileContent);

        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(jsonData)) {
          await this.importDocs(value);
        }
        this.$notify.showSuccess(`Gateway profile has been imported to Firestore successfully`);
      } catch (error) {
        this.$logger.error('Error importing data to Firestore', error);
        this.$notify.showError(error.message);
      }
      this.setLoading(false);
      this.$refs.uploader.value = null;
    },
    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.onerror = (e) => {
          reject(e.target.error);
        };
        reader.readAsText(file);
      });
    },
    async importDocs(docs) {
      const _db = await db;
      const batch = _db.batch();
      let desksCount = 0;
      const invalidDesks = [];

      for (const [key, val] of Object.entries(docs)) {
        const validDesks = [];

        for (const [id, data] of Object.entries(val.desks)) {
          if (await this.ifDeskExistsOnSite(id)) {
            validDesks.push({id, data});
            desksCount++;
          } else {
            this.$logger.warn(`Desk ${id} does not exist. Skipping this desk.`);
            invalidDesks.push(id);
          }
        }

        if (validDesks.length > 0) {
          const documentRef = _db.doc(key);
          const desksData = validDesks.reduce((acc, {id, data}) => {
            acc[id] = data;
            return acc;
          }, {});
          batch.set(documentRef, {...val, desks: desksData}, {merge: true});
        }
      }

      await batch.commit();
      this.$logger.info(`Successfully imported ${desksCount} desks`);
      if (invalidDesks.length > 0) {
        this.$logger.warn(`Skipped invalid desks: ${invalidDesks.join(', ')}`);
        throw new Error(`Skipped invalid desks: ${invalidDesks.join(', ')}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
