import {decorateSnapshot} from '@/app/firebase';
import Vue from 'vue';

/**
 * Returns the id of the document.
 *
 * @param {firebase.firestore.DocumentSnapshot|DecoratedData} doc
 * @return {string}
 */
export const byId = doc => doc && doc.id;

/**
 * Returns the ref.path of the document.
 *
 * @param {firebase.firestore.DocumentSnapshot|DecoratedData} doc
 * @return {string}
 */
export const byRef = doc => doc && doc.ref && doc.ref.path;
/**
 * Returns the ref.path of the document.
 *
 * @param {firebase.firestore.DocumentSnapshot|DecoratedData} doc
 * @return {string}
 */
export const byRefId = doc => doc && doc.ref && doc.ref.id;

/**
 * Return the last ID in a path string.
 * e.g. ns/tst/sites/hq/spaces/d1 will return d1
 *
 * @param {string} path
 * @return {string}
 */
export const lastPathId = path => {
  const segments = path.split('/');
  return segments[segments.length - 1];
};

/**
 * Utilities relating to firestore and vuex.
 */
export default class FirestoreUtil {
  /**
   * Apply the changes in snapshot to the array property of the given object.
   *
   * @param {Object} obj
   * @param {string} name The name of the array property we are to update
   * @param {firebase.firestore.QuerySnapshot} snapshot
   */
  static applyQuerySnapshotUpdates(obj, name, snapshot) {
    const array = obj[name] || Vue.set(obj, name, []);
    const actions = {
      /** @param {firebase.firestore.DocumentChange} change */
      added({newIndex, doc}) {
        array.splice(newIndex, 0, decorateSnapshot(doc));
      },
      /** @param {firebase.firestore.DocumentChange} change */
      modified({oldIndex, newIndex, doc}) {
        if (oldIndex === newIndex) {
          // hasn't moved
          array[newIndex] = decorateSnapshot(doc);
        } else {
          Vue.delete(array, oldIndex);
          array.splice(newIndex, 0, decorateSnapshot(doc));
        }
      },
      /** @param {firebase.firestore.DocumentChange} change */
      removed({oldIndex}) {
        Vue.delete(array, oldIndex);
      }
    };

    const docChanges = snapshot.docChanges();
    docChanges.forEach(c => actions[c.type](c));
  }

  /**
   * Index the updates in snapshot into the dict object. The indexFn will be used to create keys, by default the
   * document id will be used.
   *
   * @param {Object.<string,T>|function(firebase.firestore.DocumentSnapshot,string):Object.<string,T>} dict Either an
   *     object where the indexed document will be placed or a function that takes in the DocumentSnapshot and the
   *     update type and returns the object to store the indexed data.
   * @param {firebase.firestore.QuerySnapshot} snapshot
   * @param {function(firebase.firestore.DocumentSnapshot):string} [indexFn]
   * @param {function(firebase.firestore.DocumentSnapshot):T} [decorate] A function that converts a document snapshot
   *     into data that we care about. Defaults to decorateSnapshot. If the function returns a falsy value then the
   *     data will not be indexed.
   * @template {DecoratedData} T
   */
  static indexQuerySnapshotUpdates(dict, snapshot, indexFn = byId, decorate = decorateSnapshot) {
    // normalise the dict
    if (typeof dict !== 'function') {
      const origDict = dict;
      dict = () => origDict;
    }
    if (!indexFn) indexFn = byId;

    const actions = {
      /** @param {firebase.firestore.DocumentChange} change */
      added({doc, type}) {
        const target = dict(doc, type);
        if (!target) return;
        const data = decorate(doc);
        if (!data) return;
        const key = indexFn(doc);
        if (!key) return;
        Vue.set(target, key, data);
      },
      /** @param {firebase.firestore.DocumentChange} change */
      modified({doc, type}) {
        const data = decorate(doc);
        if (!data) return;
        const target = dict(doc, type);
        if (!target) return;
        const key = indexFn(doc);
        if (!key) return;
        if (key in target) {
          target[key] = data;
        } else {
          Vue.set(target, key, data);
        }
      },
      /** @param {firebase.firestore.DocumentChange} change */
      removed({doc, type}) {
        const target = dict(doc, type);
        if (target) {
          const key = indexFn(doc);
          if (key) {
            Vue.delete(target, key);
          }
        }
      }
    };

    const docChanges = snapshot.docChanges();
    docChanges.forEach(c => actions[c.type](c));
  }
}
