<template>
  <v-btn
      outlined
      color="accent darken-1"
      :disabled="!selectedGateway"
      @click="handleFileExport">
    <v-icon left>mdi-export</v-icon>
    Export
  </v-btn>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import {db} from '@/app/firebase';

export default {
  name: 'GatewayExport',
  computed: {
    ...mapState('dashboard/gateway', [
      'selectedClient',
      'selectedSite',
      'selectedGateway',
      'loading'
    ])
  },
  methods: {
    ...mapMutations('dashboard/gateway', [
      'setLoading'
    ]),

    async handleFileExport() {
      try {
        this.setLoading(true);
        const _db = await db;
        const docPath = `ns/${this.selectedClient.id}/sites/${this.selectedSite.id}/gateways/${this.selectedGateway}`;
        const snap = await _db.doc(docPath).get();
        const data = snap.data();

        const wrappedData = {
          docs: {
            [docPath]: {
              desks: data.desks
            }
          }
        };
        const asJSON = JSON.stringify(wrappedData, null, 2);
        const blob = new Blob([asJSON], {type: 'text/json'});
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        // eslint-disable-next-line max-len
        downloadLink.download = `export-${this.selectedClient.id}-${this.selectedSite.id}-${this.selectedGateway}.json`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        this.$logger.error('Error exporting data', error);
        this.$notify.showError(error.message);
      }
      this.setLoading(false);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
