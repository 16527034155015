import Vue from 'vue';
import App from './App.vue';
import './core/plugins/logging';
import router from './core/router';
import store from './store';
import vuetify from './core/plugins/vuetify';
import {Logger} from '@vanti/vue-logger';
import {Notify} from '@vanti/vue-notify';
import Vuelidate from 'vuelidate';

const log = Logger.get('main');
log.info(`Current application version: ${store.state.app.versions.VERSION} @ ${store.state.app.versions.COMMITHASH}`);

Vue.config.productionTip = false;

Vue.use(Notify, {store});
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  vuetify,
  created() {
    store.dispatch('auth/bind')
        .catch(err => log.error('Failed to bind the server ', err));
  },
  render: h => h(App)
}).$mount('#app');
