import Loading from '@/auth/views/Loading';

import BillingView from '@/dashboard/views/BillingView';
import StatsView from '@/dashboard/views/StatsView';
import GatewayView from '@/dashboard/views/GatewayView';

export default [
  {
    path: '/',
    name: 'loading',
    component: Loading
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/auth/views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: {
      name: 'Dashboard',
      template: '<router-view/>'
    },
    children: [
      {
        name: 'stats',
        path: 'stats',
        component: StatsView
      },
      {
        name: 'billing',
        path: 'billing',
        component: BillingView
      },
      {
        name: 'gateway',
        path: 'gateway',
        component: GatewayView
      }
    ]
  }

];
