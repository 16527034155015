import {Logger} from '@vanti/vue-logger';
import DeferUtil from '@/app/store/defer-util';
import FirestoreUtil from './firestore-util';
import {db, decorateSnapshot} from '@/app/firebase';
import firebase from 'firebase/app';
import Vue from 'vue';

const log = Logger.get('gateway');
const getDefaultGatewayState = () => {
  return {
    clients: [],
    selectedClient: null,
    sites: [],
    selectedSite: null,
    gateways: {},
    selectedGateway: null,
    loading: false
  };
};


export default {
  namespaced: true,
  state: getDefaultGatewayState(),
  getters: {
    gatewaysInSelectedSite(state) {
      if (!state.selectedSite) {
        return [];
      }
      return Object.keys(state.gateways).map((key) => key);
    },
    desksInSelectedGateway(state) {
      if (!state.selectedGateway || !state.gateways[state.selectedGateway].desks) {
        return [];
      }
      return Object.keys(state.gateways[state.selectedGateway].desks).map(deskId => {
        return {
          deskId,
          ...state.gateways[state.selectedGateway].desks[deskId]
        };
      });
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),

    setClients(state, payload) {
      state.clients = payload;
    },
    setSelectedClient(state, payload) {
      state.selectedClient = payload;
    },
    setSites(state, payload) {
      state.sites = payload;
    },
    setSelectedSite(state, payload) {
      state.selectedSite = payload;
    },
    setGateways(state, snapshot) {
      FirestoreUtil.indexQuerySnapshotUpdates(state.gateways, snapshot);
    },
    setSelectedGateway(state, payload) {
      state.selectedGateway = payload;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    resetSelectedGateway(state) {
      state.selectedGateway = null;
    },
    resetSelectedValues(state) {
      state.selectedSite = null;
      state.selectedGateway = null;
    },
    updateDesk(state, payload) {
      Vue.set(state.selectedGateway.desks, payload.deskId, {
        hubPort: payload.hubPort,
        serialNumber: payload.serialNumber
      });
    },
    deleteDesk(state, deskId) {
      const desks = state.selectedGateway.desks;
      if (desks && desks[deskId]) {
        Vue.delete(desks, deskId);
      }
    },
    resetState(state) {
      Object.assign(state, getDefaultGatewayState());
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      async handler({state, commit, rootGetters}, authUser) {
        const hasAccessToGateway = rootGetters['auth/hasAccessTo'].dashboard.gateway;
        if (!authUser || !hasAccessToGateway) {
          commit('resetState');
          return;
        }

        const defer = {};
        const _db = await db;
        defer.gateways = _db.collection('ns').onSnapshot(
            snapshot => commit('setClients', snapshot.docs.map(doc => decorateSnapshot(doc))),
            err => log.error(`gateways.onSnapshot`, err)
        );
        commit('defer', defer);
      }
    },
    async fetchSites({state, commit, rootGetters}) {
      const defer = {};
      const _db = await db;
      defer.gateways = _db.collection(`ns/${state.selectedClient.id}/sites`).onSnapshot(
          snapshot => commit('setSites', snapshot.docs.map(doc => decorateSnapshot(doc))),
          err => log.error(`gateways.onSnapshot`, err)
      );
      commit('defer', defer);
    },
    async fetchGateways({state, commit, rootGetters}) {
      const defer = {};
      const _db = await db;
      defer.gateways = _db.collection(`ns/${state.selectedClient.id}/sites/${state.selectedSite.id}/gateways`)
          .onSnapshot(
              snapshot => commit('setGateways', snapshot),
              err => log.error(`gateways.onSnapshot`, err)
          );
      commit('defer', defer);
    },
    async addDesk({state, commit}, payload) {
      try {
        const _db = await db;
        const deskRef = await _db.collection(`ns/${state.selectedClient.id}/sites/${state.selectedSite.id}/gateways`)
            .doc(state.selectedGateway);

        const deskId = payload.deskId;
        const fieldPath = new firebase.firestore.FieldPath('desks', deskId);

        const newDeskData = {
          hubPort: payload.hubPort,
          serialNumber: payload.serialNumber
        };

        await deskRef.update(fieldPath, newDeskData);
      } catch (error) {
        log.error('Error adding desk:', error);
        throw new Error(error);
      }
    },
    async updateDesk({state, commit}, payload) {
      try {
        const _db = await db;
        const deskRef = await _db
            .collection(`ns/${state.selectedClient.id}/sites/${state.selectedSite.id}/gateways`)
            .doc(state.selectedGateway);

        const newDeskId = payload.deskId;
        const oldDeskId = payload.oldDeskId;

        // Fetch the existing desks data
        const documentSnapshot = await deskRef.get();
        const desksData = documentSnapshot.exists ? documentSnapshot.data().desks : {};

        const updateData = {
          hubPort: payload.hubPort,
          serialNumber: payload.serialNumber
        };

        await deskRef.set({
          desks: {
            [newDeskId]: updateData
          }
        }, {merge: true});

        // Remove the old entry if it exists
        if (oldDeskId && oldDeskId !== newDeskId) {
          desksData[oldDeskId] = firebase.firestore.FieldValue.delete();

          await deskRef.set({
            desks: desksData
          }, {merge: true});
        }
      } catch (error) {
        log.error('Error updating desk:', error);
        throw new Error(error);
      }
    },
    async deleteDesk({state, commit, dispatch}, deskId) {
      try {
        const _db = await db;
        const deskRef = await _db.collection(`ns/${state.selectedClient.id}/sites/${state.selectedSite.id}/gateways`)
            .doc(state.selectedGateway);

        const fieldPath = new firebase.firestore.FieldPath('desks', deskId);
        await deskRef.update(fieldPath, firebase.firestore.FieldValue.delete());
        commit('deleteDesk', deskId);
      } catch (error) {
        log.error('Error deleting desk:', error);
        throw new Error(error);
      }
    },
    async ifDeskExistsOnSite({state, commit, dispatch}, deskId) {
      try {
        const collectionPath = `ns/${state.selectedClient.id}/sites/${state.selectedSite.id}/bookables`;
        const docRef = firebase.firestore().doc(`${collectionPath}/${deskId}`);
        const docSnapshot = await docRef.get();

        return docSnapshot.exists;
      } catch (error) {
        log.error('Error checking document:', error);
        throw new Error(error);
      }
    },
    async updateTimezone({state, commit, dispatch}, timezone) {
      try {
        const _db = await db;
        const gatewayRef = await _db.collection(`ns/${state.selectedClient.id}/sites/${state.selectedSite.id}/gateways`)
            .doc(state.selectedGateway);

        await gatewayRef.update({
          timeZone: timezone
        });
      } catch (error) {
        log.error('Error updating timezone:', error);
        throw new Error(error);
      }
    }
  }
};
