import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/auth/store';
import app from '@/app/store';
import dashboard from '@/dashboard/store';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    }
  },
  actions: {
    init() {
    },
    onAuthStateChanged(context, authUser) {
    }
  },
  modules: {
    app,
    auth,
    dashboard
  }
});

store.dispatch('init')
    .catch(err => log.error('in store init', err));

export default store;
