var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"billing pa-4 mx-lg-auto"},[_c('div',{staticClass:"d-flex mb-4 align-center"},[_c('v-select',{staticClass:"mr-4",attrs:{"label":"Reseller","items":_vm.resellerSelects,"hide-details":""},on:{"change":_vm.setReseller},model:{value:(_vm.reseller),callback:function ($$v) {_vm.reseller=$$v},expression:"reseller"}}),_c('v-spacer'),_c('v-select',{staticClass:"mr-4",attrs:{"label":"Statement Date","items":_vm.statementSelects,"hide-details":""},model:{value:(_vm.statement),callback:function ($$v) {_vm.statement=$$v},expression:"statement"}}),_c('v-btn',{attrs:{"outlined":"","color":"accent darken-1","href":_vm.downloadUrl,"download":_vm.downloadFileName}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download CSV ")],1)],1),_vm._l((Object.keys(_vm.itemsByCustomer).sort()),function(ns){return _c('v-card',{key:ns,staticClass:"mb-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.itemsByCustomer[ns].title))]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.dataHeaders,"items":_vm.itemsByCustomer[ns].items,"sort-by":_vm.sortByPerCustomer[ns],"custom-sort":_vm.customSort,"disable-pagination":"","hide-default-footer":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.sortByPerCustomer, ns, $event)},"update:sort-by":function($event){return _vm.$set(_vm.sortByPerCustomer, ns, $event)}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.unit.title)+" | "+_vm._s(item.pricing.title))]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"numeric"},[_vm._v(_vm._s(item.quantity))])]}}],null,true)}),_c('v-card-subtitle',[_vm._v("Totals")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.dataHeaders,"items":_vm.totalsByCustomer[ns],"disable-pagination":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',{style:(("width:" + (headers[1].width) + ";"))},[_c('span',{staticClass:"numeric"},[_vm._v(_vm._s(item.quantity))])])])]}}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }