<template>
  <v-data-table
      height="700px"
      fixed-header
      :headers="headers"
      :items="desksInSelectedGateway"
      :loading="loading"
      item-key="deskId"
      sort-by="deskId"
      :footer-props="footerProps"
      :search="search">
    <template #top>
      <div v-if="selectedGateway" class="d-flex pa-4 align-center">
        <div class="text-h5">Desks in {{ selectedGateway }}</div>
        <v-spacer/>
        <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Search desks"
            class="gateway__search"
            single-line
            hide-details
            clearable/>
        <v-btn
            class="ml-8"
            @click="addItem"
            color="primary">
          Add new entry
        </v-btn>
        <v-dialog
            v-model="editTzDialog"
            persistent
            max-width="400px">
          <template #activator="{ on, attrs }">
            <v-btn
                class="ml-4"
                color="primary"
                v-bind="attrs"
                v-on="on">
              Edit timezone
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Change timezone</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="timezone" label="Timezone"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                  color="error"
                  text
                  @click="editTzDialog = false">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  @click="updateTz">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-dialog v-model="dialog" max-width="600px" persistent eager>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} Desk</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-model="$v.editedItem.deskId.$model"
                      label="Desk ID"
                      :error-messages="deskIdErrors"
                      @blur.native="$v.editedItem.deskId.$touch()"/>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      type="number"
                      v-model.number="$v.editedItem.hubPort.$model"
                      label="Hub Port"
                      :error-messages="hubPortErrors"
                      @blur.native="$v.editedItem.hubPort.$touch()"/>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      type="number"
                      v-model.number="$v.editedItem.serialNumber.$model"
                      label="Serial Number"
                      :error-messages="serialNumberErrors"
                      @blur.native="$v.editedItem.serialNumber.$touch()"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="closeDialog" color="error">Close</v-btn>
            <v-btn
                text
                @click="save"
                color="blue darken-1"
                :loading="loading"
                :disabled="$v.$invalid || !isEdited">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template #item.actions="{ item }">
      <v-btn
          icon
          @click="editItem(item)">
        <v-icon size="20">mdi-pencil</v-icon>
      </v-btn>
      <v-btn
          icon
          @click="deleteItem(item)">
        <v-icon size="20">mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, integer} from 'vuelidate/lib/validators';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {isEqual} from 'lodash';

export default {
  name: 'GatewayProfileTable',
  mixins: [validationMixin],
  data() {
    return {
      headers: [
        {text: 'Desk ID', value: 'deskId', sortable: true, width: '40%'},
        {text: 'Hub Port', value: 'hubPort', sortable: false, width: '20%'},
        {text: 'Serial Number', value: 'serialNumber', sortable: false, width: '20%'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      },
      search: '',
      editedItem: {
        deskId: '',
        hubPort: null,
        serialNumber: null
      },
      originalItem: {},
      dialog: false,
      editTzDialog: false,
      selectedTimezone: '',
      editedIndex: -1
    };
  },
  validations: {
    editedItem: {
      deskId: {
        async isExistsOnSite(value) {
          return !value || (await this.ifDeskExistsOnSite(value));
        },
        isUniqueInSelectedGateway(value) {
          const isUnique = !this.desksInSelectedGateway.some(desk =>
            desk.deskId === value && desk.deskId !== this.originalItem.deskId
          );
          return isUnique;
        }
      },
      hubPort: {
        required,
        integer,
        isDuplicate() {
          return !this.isDuplicate();
        }
      },
      serialNumber: {
        required,
        integer,
        isDuplicate() {
          return !this.isDuplicate();
        }
      }
    }
  },
  computed: {
    ...mapState('dashboard/gateway', [
      'selectedGateway', 'gateways', 'loading'
    ]),
    ...mapGetters('dashboard/gateway', [
      'desksInSelectedGateway'
    ]),

    timezone: {
      get() {
        return this.selectedGateway ? this.gateways[this.selectedGateway].timeZone : '';
      },
      set(value) {
        this.selectedTimezone = value;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New' : 'Edit';
    },
    isEdited() {
      return !isEqual(this.editedItem, this.originalItem);
    },
    deskIdErrors() {
      const errors = [];
      const {deskId} = this.$v.editedItem;
      if (!deskId.$dirty) return errors;
      !deskId.isExistsOnSite && errors.push('Desk ID does not exist on this site');
      !deskId.isUniqueInSelectedGateway && errors.push('Desk ID must be unique in selected gateway');
      return errors;
    },
    hubPortErrors() {
      const errors = [];
      const {hubPort} = this.$v.editedItem;
      if (!hubPort.$dirty) return errors;
      !hubPort.required && errors.push('Hub Port is required'),
      !hubPort.integer && errors.push('Hub Port must be an integer'),
      !hubPort.isDuplicate && errors.push('Hub Port and Serial Number must be unique');
      return errors;
    },
    serialNumberErrors() {
      const errors = [];
      const {serialNumber} = this.$v.editedItem;
      if (!serialNumber.$dirty) return errors;
      !serialNumber.required && errors.push('Hub Port is required'),
      !serialNumber.integer && errors.push('Hub Port must be an integer'),
      !serialNumber.isDuplicate && errors.push('Hub Port and Serial Number must be unique');
      return errors;
    }
  },
  watch: {
    dialog(val) {
      val || this.closeDialog();
    }
  },
  methods: {
    ...mapActions('dashboard/gateway', [
      'updateTimezone',
      'ifDeskExistsOnSite',
      'addDesk',
      'updateDesk',
      'deleteDesk'
    ]),
    ...mapMutations('dashboard/gateway', [
      'setLoading'
    ]),

    addItem() {
      this.editedItem = {
        deskId: '',
        hubPort: null,
        serialNumber: null
      };
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.desksInSelectedGateway.indexOf(item);
      this.editedItem = {...item};
      this.originalItem = {...item};
      this.dialog = true;
    },
    async deleteItem(item) {
      try {
        this.setLoading(true);
        this.editedIndex = this.desksInSelectedGateway.indexOf(item);
        await this.deleteDesk(item.deskId);
        this.$notify.showSuccess(`Desk has been deleted successfully`);
      } catch (error) {
        this.$notify.showError(error.message);
      }
      this.setLoading(false);
    },
    async updateTz() {
      this.setLoading(true);
      try {
        await this.updateTimezone(this.selectedTimezone);
        this.$notify.showSuccess(`Timezone has been updated successfully`);
      } catch (error) {
        this.$notify.showError(error.message);
      }
      this.setLoading(false);
      this.editTzDialog = false;
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.setLoading(true);
      const deskData = {
        deskId: this.editedItem.deskId,
        oldDeskId: this.originalItem.deskId,
        hubPort: +this.editedItem.hubPort,
        serialNumber: +this.editedItem.serialNumber
      };
      if (this.editedIndex > -1) {
        try {
          await this.updateDesk(deskData);
          this.$notify.showSuccess(`Desk has been updated successfully`);
        } catch (error) {
          this.$notify.showError(error.message);
        }
      } else {
        try {
          await this.addDesk(deskData);
          this.$notify.showSuccess(`Desk has been added successfully`);
        } catch (error) {
          this.$notify.showError(error.message);
        }
      }
      this.setLoading(false);
      this.closeDialog();
    },
    isDuplicate() {
      if (isEqual(this.editedItem, this.originalItem)) {
        return false;
      }
      return this.desksInSelectedGateway.some(desk =>
        desk.hubPort === this.editedItem.hubPort &&
        desk.serialNumber === this.editedItem.serialNumber
      );
    },
    closeDialog() {
      this.$v.$reset();
      this.dialog = false;
      this.editedItem = {
        deskId: '',
        hubPort: null,
        serialNumber: null
      };
      this.originalItem = {};
      this.editedIndex = -1;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
