/**
 * Returns true if any of the values in anyOf are present in arr
 *
 * @param {[]} arr
 * @param {[]} anyOf
 * @return {boolean}
 */
export function arrayContainsAny(arr, anyOf) {
  for (const el of arr) {
    if (anyOf.includes(el)) {
      return true;
    }
  }
  return false;
}
