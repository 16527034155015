<template>
  <v-app class="admin-app">
    <nav-bar v-if="isAuthenticated"/>
    <v-main>
      <no-access v-if="isAuthenticated && !hasAppAccess"/>
      <router-view v-else/>
    </v-main>
    <!--    <v-dialog
        max-width="250"
        v-model="dialog.show">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text>{{ dialog.body }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click.stop="dialog.cancel()" text>
            Cancel
          </v-btn>
          <v-btn @click.stop="dialog.confirm()" color="secondary">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <notify-toast/>
    <v-snackbar :value="!upToDate" :timeout="3600000" bottom left dark>
      <v-icon dark class="mr-4">mdi-update</v-icon>
      A new version is available
      <v-btn @click="reload" dark text>Reload</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import NavBar from '@/core/components/NavBar';
import NoAccess from '@/auth/views/NoAccess';
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  components: {
    NavBar,
    NoAccess
  },
  computed: {
    // ...mapGetters(['dialog']),
    ...mapGetters('auth', ['hasAppAccess', 'isAuthenticated']),
    ...mapGetters('app', ['upToDate'])
  },
  mounted() {
    this.$store.dispatch('app/bind')
        .catch(err => {
          this.$logger.error('Failed to bind versions module', err);
        });
  },
  destroyed() {
    this.$store.dispatch('app/unbind');
  },
  methods: {
    reload() {
      location.reload();
    }
  }
};
</script>
