<template>
  <v-container class="gateway pa-4 mx-lg-auto">
    <gateway-selector>
      <template #append>
        <gateway-import/>
        <gateway-export/>
      </template>
    </gateway-selector>
    <gateway-profile-table/>
  </v-container>
</template>

<script>
import GatewayProfileTable from '@/dashboard/components/GatewayProfileTable';
import GatewaySelector from '@/dashboard/components/GatewaySelector';
import GatewayImport from '@/dashboard/components/GatewayImport';
import GatewayExport from '@/dashboard/components/GatewayExport';

export default {
  name: 'GatewayView',
  components: {
    GatewayProfileTable,
    GatewaySelector,
    GatewayImport,
    GatewayExport
  }
};
</script>

<style lang="scss" scoped>

</style>
