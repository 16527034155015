import {auth} from '@/app/firebase';
import {Logger} from '@vanti/vue-logger';
import {arrayContainsAny} from '@/util/array';

const log = Logger.get('auth');

const rolesWithAppAccess = [
  'admin.super',
  'admin.stats',
  'admin.billing',
  'admin.gateway'
];

export default {
  namespaced: true,
  state: {
    authUser: null,
    /**
     * Records any errors that happened during the last attempt to determine the auth state
     *
     * @type {null|Error}
     */
    authError: null,
    profile: {
      displayName: '',
      email: ''
    }
  },
  getters: {
    authUser(state) {
      return state.authUser || {};
    },
    hasAuthUser(state) {
      return Boolean(state.authUser);
    },
    authUserClaims(state) {
      return (state.authUser && state.authUser.token.claims) || {};
    },
    displayName(state) {
      return state.profile.displayName;
    },
    hasAppAccess(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, rolesWithAppAccess);
    },
    hasAccessTo(state, getters) {
      const roles = getters.userRoles;

      const statsAdmin = arrayContainsAny(roles, ['admin.super', 'admin.stats']);
      const billingAdmin = arrayContainsAny(roles, ['admin.super', 'admin.billing']);
      const gatewayAdmin = arrayContainsAny(roles, ['admin.super', 'admin.gateway']);

      return {
        'dashboard': {
          'stats': statsAdmin,
          'billing': billingAdmin,
          'gateway': gatewayAdmin
        }
      };
    },
    userEmail(state) {
      return state.profile.hasOwnProperty('email') ? state.profile.email : '';
    },
    userRoles(state, getters) {
      const claims = getters.authUserClaims;
      if (!claims.hasOwnProperty('van')) {
        return [];
      }
      if (claims.van.hasOwnProperty('r')) {
        return claims.van.r;
      }
      return [];
    },
    isAdmin(state, getters) {
      const roles = getters.userRoles;
      return roles.includes('admin.super');
    },
    isAuthenticated(state) {
      return Boolean(state.authUser);
    }
  },
  mutations: {
    setAuthUser(state, authUser) {
      state.authError = null;
      state.authUser = authUser;
      state.profile.displayName = authUser.displayName;
      state.profile.email = authUser.email;
    },
    updateAuthUser(state, authUser) {
      Object.assign(state.authUser, authUser);
      state.profile.displayName = authUser.displayName;
      state.profile.email = authUser.email;
    },
    clearUser(state) {
      state.authError = null;
      state.authUser = null;
      state.profile.displayName = '';
      state.profile.email = '';
    },
    setAuthError(state, error) {
      this.authError = error;
    }
  },
  actions: {
    async bind({commit, dispatch}) {
      log.debug('bind');
      commit('app/loading', 'auth', {root: true});
      // listen to changes in the auth state (i.e. login/out events)
      const _auth = await auth;
      log.debug('auth awaited');
      _auth.onAuthStateChanged(async user => {
        try {
          if (!user) {
            // we are anonymous/logged out
            log.debug('authStateChanged: no user');
            commit('clearUser');
            dispatch('onAuthStateChanged', null, {root: true});
          } else {
            // the user has logged in!
            log.debug('authStateChanged: user', user);
            // only store the properties we use, this may help with Vue dev tools issues
            const authUser = userInfoOnly(user);
            authUser.token = await user.getIdTokenResult();
            log.debug('authStateChanged: token', authUser.token);
            commit('setAuthUser', authUser);
            dispatch('onAuthStateChanged', authUser, {root: true});
          }
        } catch (e) {
          log.debug('authStateChanged: error processing user', e);
          commit('setAuthError', e);
        } finally {
          commit('app/loaded', 'auth', {root: true});
        }
      }, err => {
        commit('setAuthError', err);
        log.error('onAuthStateChanged', err);
      }, () => {
        log.info('onAuthStateChanged completed');
        commit('app/loaded', 'auth', {root: true});
      });
    },
    signIn(state, {email, password}) {
      return auth.then(auth => auth.signInWithEmailAndPassword(email, password));
    },
    signOut() {
      return auth.then(auth => auth.signOut());
    },
    signInPopup(context, provider) {
      log.debug('signInPopup');
      return auth.then(a => a.signInWithPopup(provider));
    },
    signInRedirect(context, provider) {
      log.debug('signInRedirect');
      return auth.then(a => a.signInWithRedirect(provider));
    },
    updateProfile({commit}, profile) {
      return auth.then(a => {
        return a.currentUser.updateProfile(profile)
            .then(() => commit('updateAuthUser', userInfoOnly(a.currentUser)));
      });
    }
  }
};

/**
 * Extract useful properties from a user to avoid potential issues storing a firebase object in vuex (can cause issues
 * with Vue dev tools.
 *
 * @param {firebase.UserInfo|null} user
 * @return {firebase.UserInfo|{}}
 */
function userInfoOnly(user) {
  if (!user) return {};
  return {
    email: user.email,
    uid: user.uid,
    displayName: user.displayName,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    providerId: user.providerId
  };
}
