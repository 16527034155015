import guard from '@/auth/router/guard';
import routes from '@/core/router/routes';
import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// handles global redirects to loading/login for us
guard(router, store);

export default router;
