<template>
  <v-navigation-drawer
      app
      dark
      permanent
      :mini-variant="drawer"
      class="draw">
    <v-list class="list" dense>
      <v-list-item class="top pl-0">
        <router-link :to="{name: 'loading'}">
          <img :src="KahuLogo" alt="Kahu">
        </router-link>
        <v-list-item-content/>
        <v-list-item-action class="top-menu">
          <account-menu/>
        </v-list-item-action>
      </v-list-item>
      <v-list-group
          :group="i.group"
          :key="i.title"
          v-for="i in menu">
        <template #activator>
          <v-list-item-icon>
            <v-icon>{{ i.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ i.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item-group class="sub-menu-item">
          <v-list-item
              :key="link.title"
              :to="link.to"
              link
              v-for="link in i.links">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ link.title }}
            </v-list-item-title>
            <v-chip v-if="link.badge" small color="accent darken-1" :style="{flexBasis: '35%'}">
              {{ link.badge }}
            </v-chip>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>
    <template #append>
      <div class="collapse">
        <v-btn icon class="close-btn" @click="drawer = !drawer">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import KahuLogo from '@/auth/assets/Kahu-Logo_letterform_orange.svg?as-is';
import AccountMenu from '@/app/components/AccountMenu';
// import SiteMenu from '@/components/SiteMenu';
import {mapGetters} from 'vuex';

export default {
  name: 'NavBar',
  components: {AccountMenu},
  data() {
    return {
      drawer: false
    };
  },
  computed: {
    ...mapGetters('auth', ['hasAccessTo']),
    KahuLogo() {
      return KahuLogo;
    },
    menu() {
      const menu = [];

      const dashboard = {
        title: 'Dashboard',
        group: 'dashboard',
        icon: 'mdi-view-dashboard',
        links: []
      };
      const dashboardAccess = this.hasAccessTo.dashboard;

      if (dashboardAccess.stats) {
        dashboard.links.push({
          title: 'Stats',
          icon: 'mdi-gauge',
          to: {name: 'stats'}
        });
      }
      if (dashboardAccess.billing) {
        dashboard.links.push({
          title: 'Billing',
          icon: 'mdi-receipt',
          to: {name: 'billing'}
        });
      }
      if (dashboardAccess.gateway) {
        dashboard.links.push({
          title: 'Gateway',
          icon: 'mdi-cloud-outline',
          to: {name: 'gateway'}
        });
      }

      if (dashboard.links.length > 0) {
        menu.push(dashboard);
      }

      return menu;
    }
  }
};
</script>

<style scoped>
.sub-menu-item {
  background-color: var(--v-grey-darken1);
}

.top {
  max-height: 56px;
}

.top:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: var(--v-grey-lighten3);
  z-index: -1;
}

.top img {
  height: 56px;
  vertical-align: top;
  z-index: 1;
}

.list {
  padding: 0;
}

.v-list-item__action--stack {
  flex-direction: row;
  align-items: center;
  /*
  align the buttons with the expand icon in the nav list. Icons are 24x24 but buttons are 36x36,
  the difference is 12px, so we adjust to the right by half of that.
  */
  margin-right: -6px;
}

.v-list-item__action--stack > :not(:last-child) {
  /* Space between buttons in the action section */
  margin-right: 8px;
}

.draw {
  box-shadow: 6px 1px 15px var(--v-grey-lighten3);
}

.theme--dark.v-list-item.v-list-item--active {
  color: var(--v-primary-base) !important;
}

.v-list-group .v-list-group__header.v-list-item .v-list-item__title,
.v-list-group > .v-list-group__header .v-icon,
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-grey-lighten3) !important;
}

.v-icon.theme--dark {
  color: inherit;
}

.theme--dark.v-navigation-drawer {
  background-color: var(--v-grey-base);
}

.collapse {
  text-align: right;
  padding: 0 10px 10px 10px;
}

.collapse >>> .v-icon {
  transform: rotate(0);
  transition: transform 200ms cubic-bezier(0.25, 0.8, 0.5, 1);
  color: var(--v-grey-lighten3);
}

.v-navigation-drawer--mini-variant .collapse >>> .v-icon {
  transform: rotate(180deg);
}
</style>

