import {Logger} from '@vanti/vue-logger';
import DeferUtil from '@/app/store/defer-util';
import {db, decorateSnapshot} from '@/app/firebase';

const log = Logger.get('stats');

export default {
  state: {
    countDoc: {}
  },
  getters: {
    stats(state) {
      return state.countDoc;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setLatest(state, doc) {
      state.countDoc = doc;
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      async handler({state, commit, rootGetters}, authUser) {
        const hasAccessToStats = rootGetters['auth/hasAccessTo'].dashboard.stats;
        if (!authUser || !hasAccessToStats) {
          commit('setLatest', {});
          return;
        }

        const defer = {};
        const _db = await db;
        defer.ns = _db.doc(`stats/latest`).onSnapshot(
            snapshot => commit('setLatest', decorateSnapshot(snapshot)),
            err => log.error(`stats/latest.onSnapshot`, err)
        );
        commit('defer', defer);
      }
    }
  }
};
