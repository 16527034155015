import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        'primary': {
          base: '#00C2D4',
          /**
           * darker colours aren't defined in the brand guidelines so darken1 is
           * taken from a shade generator at 30% darker than the base colour
           */
          darken1: '#008894',
          lighten1: '#66DAE5'
        },
        'secondary': {
          base: '#FFA600',
          darken1: '#b37400',
          lighten1: '#FFC966'
        },
        'accent': {
          base: '#CDDC00',
          darken1: '#909a00',
          lighten1: '#E1EA66'
        },
        'grey': {
          base: '#4B4F54',
          darken1: '#35373b',
          lighten1: '#6F7276',
          lighten2: '#939598',
          lighten3: '#B7B8BA',
          lighten4: '#DBDBDC',
          lighten5: '#EDEDED'
        },
        'info': '#4735CD',
        'success': '#03BD5B',
        'warning': '#FF9947',
        'error': '#DB5F62',

        'free': '#03bd5a',
        'booked': '#FFBF21',
        'in-use': '#ff9947',
        'occupied': '#db5e62',
        'disabled': '#d0d0d0'
      }
    }
  }
});
