import {Logger} from '@vanti/vue-logger';
import DeferUtil from '@/app/store/defer-util';
import {db, decorateSnapshot} from '@/app/firebase';

const log = Logger.get('billing');

export default {
  state: {
    resellers: [],
    statements: []
  },
  getters: {
    resellers(state) {
      return state.resellers;
    },
    statements(state) {
      return state.statements.sort((a, b) => b.date.toMillis() - a.date.toMillis());
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    resellers(state, docs) {
      state.resellers = docs;
    },
    statements(state, docs) {
      state.statements = docs;
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      async handler({state, commit, rootGetters}, authUser) {
        const hasAccessToBilling = rootGetters['auth/hasAccessTo'].dashboard.billing;
        if (!authUser || !hasAccessToBilling) {
          commit('resellers', []);
          commit('statements', []);
          return;
        }

        const defer = {};
        const _db = await db;
        defer.resellers = _db.collection(`resellers`).onSnapshot(
            snapshot => commit('resellers', snapshot.docs.map(doc => decorateSnapshot(doc))),
            err => log.error(`resellers.onSnapshot`, err)
        );
        commit('defer', defer);
      }
    },
    setReseller({state, commit, rootGetters}, reseller) {
      const defer = {};
      defer.statements = reseller.ref.collection(`statements`).onSnapshot(
          snapshot => commit('statements', snapshot.docs.map(doc => decorateSnapshot(doc))),
          err => log.error(`statements.onSnapshot`, err)
      );
      commit('defer', defer);
    }
  }
};
